@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(../public/Montserrat.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: Montserrat, -apple-system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EFECEC;
}

code {
    font-family: Montserrat, -apple-system, sans-serif;
}
